<template>
  <div>
    <CRow ref="shopCategorySection">
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="d-flex align-items-center">
                <CIcon name="cil-house" class="mr-2" />
                Create New Product
              </div>
            </slot>
          </CCardHeader>

          <CCardBody>
            <ValidationObserver v-slot="{ handleSubmit }">
              <CForm role="form" @submit.prevent="handleSubmit(createProduct)">
                <CRow>
                  <CCol col="12">
                    <h5 class="font-weight-bold">基本情報</h5>
                    <hr>
                  </CCol>
                  <CCol col="12" md="4" xl="2">
                    <ValidationProvider
                        :rules="{ featuredImage: ['image/png','image/svg+xml','image/jpeg'] }"
                        v-slot="{ errors, invalid, validated }"
                    >
                      <ProductUploadFeatured v-model="product.featuredImage" />
                      <div class="text-danger mt-2" v-if="validated && invalid && errors[0]">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol col="12" md="8" xl="10">
                    <CRow>
                      <CCol col="12">
                        <ValidationProvider
                            :rules="{ nameRequired: true }"
                            v-slot="{ errors, invalid, validated }"
                        >
                          <label>店舗名 <span class="text-danger">*</span> </label>
                          <CInput
                              placeholder="店舗名を入力"
                              v-model="product.name"
                              :isValid="!validated ? null : !invalid"
                          >
                            <template #invalid-feedback v-if="errors[0]">
                              <div class="invalid-feedback">{{ errors[0] }}</div>
                            </template>
                          </CInput>
                        </ValidationProvider>
                      </CCol>
                      <CCol col="12" class="mb-3">
                        <label>カテゴリー</label>
                        <v-select :multiple="true" :options="categoryOptions"
                                  :reduce="categoryItem => categoryItem.code" v-model="product.categoryIds"></v-select>
                      </CCol>
                      <CCol col="12" md="6" lg="3" class="mb-3">
                        <ValidationProvider vid="originalPrice" :rules="{originalPriceAndSale: ['salePrice'] }"
                                            v-slot="{ errors, invalid, validated }">
                          <CInput label="Original Price" v-model="product.original_price" type="number"
                                  :isValid="!validated ? null : !invalid">
                            <template #invalid-feedback v-if="errors[0]">
                              <div class="invalid-feedback">{{ errors[0] }}</div>
                            </template>
                          </CInput>
                        </ValidationProvider>
                      </CCol>
                      <CCol col="12" md="6" lg="3" class="mb-3">
                        <ValidationProvider :rules="{ salePriceAndOriginal: ['originalPrice'] }"
                                            v-slot="{ errors, invalid, validated }" vid="salePrice">
                          <CInput label="Sale Price" v-model="product.price" type="number"
                                  :isValid="!validated ? null : !invalid">

                            <template #invalid-feedback v-if="errors[0]">
                              <div class="invalid-feedback">{{ errors[0] }}</div>
                            </template>
                          </CInput>
                        </ValidationProvider>
                      </CCol>
                      <CCol col="12" md="6" lg="3" class="mb-3">
                        <CInput label="個数" v-model="product.unit"/>
                      </CCol>
                      <CCol col="12" md="6" lg="3" class="mb-3">
                        <label>在庫状況</label>
                        <v-select :options="productStatusList" :reduce="statusItem => statusItem.code"
                                  v-model="product.status" :clearable="false"></v-select>
                      </CCol>
                      <CCol col="12" class="mb-3">
                        <label>Excerpt</label>
                        <textarea class="form-control" rows="3" v-model="product.excerpt"/>
                      </CCol>
                      <CCol col="12">
                        <label>商品概要</label>
                        <vue-editor v-model="product.description" :editor-toolbar="editorToolbar" useCustomImageHandler
                                    @image-added="uploadImageContent"/>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol class="text-center text-danger" col="12" v-if="errorMessage !== ''">
                    {{ errorMessage }}
                  </CCol>

                  <CCol col="12" class="mt-3">
                    <hr>
                  </CCol>
                  <CCol col="12" class="text-center">
                    <CButton color="primary" type="submit">提出</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {mimes, required} from "vee-validate/dist/rules";
import ProductUploadFeatured from '../../components/products/ProductUploadFeatured';
import {mapState} from "vuex";
import {editorToolbar, productStatusList} from "../../utils/constants";
import {VueEditor} from 'vue2-editor'
import {originalPriceAndSaleRuleObj, salePriceAndOriginalRuleObj} from "../../utils/helpers";

export default {
  name: "ProductCreate",

  computed: {
    ...mapState({
      categoryList: state => state.productCategory.productCategoryList,
    }),

    categoryOptions() {
      return this.categoryList.map(categoryItem => {
        return {
          code: categoryItem.id,
          label: categoryItem.name,
        }
      });
    }
  },

  data() {
    return {
      product: {
        featuredImage: null,
        name: '',
        categoryIds: [],
        price: '',
        original_price: '',
        unit: null,
        status: 1,
        description: '',
        excerpt: '',
      },

      productStatusList,
      editorToolbar,

      errorMessage: '',
    }
  },

  components: {
    VueEditor,
    ValidationProvider,
    ValidationObserver,
    ProductUploadFeatured
  },

  methods: {
    createProduct() {
      this.errorMessage = '';
      this.loading = true;

      this.$store.dispatch('gallery/uploadImage', {
        file: this.product.featuredImage,
        type: 'product_featured_image',
      })
          .then(res => {
            let {success, data} = res.data;

            if(success) {
              this.product.featured_image = data.id;

              return this.$store.dispatch('product/createProduct', this.product);
            } else {
              return { data: { success: false, message: 'Upload Image Fail' }};
            }
          })
          .then(res => {
            let { success, message } = res.data;

            if(success) {
              this.$router.push({name: 'Products'});
            } else{
              this.errorMessage = message;
            }
          })
          .catch(err => {
            let response = err.response;

            if (response.status !== 422) {
              this.errorMessage = "Create Product Failed";
            } else {
              let errors = response.data.errors;
              let errorKey = Object.keys(errors)[0];

              this.errorMessage = errors[errorKey][0];
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },

    uploadImageContent(file, Editor, cursorLocation, resetUploader) {
      return this.$store.dispatch('gallery/uploadImage', {
        file: file,
        type: 'product_content_image',
      })
          .then(result => {
            let { success, data } = result.data;

            if(success) {
              Editor.insertEmbed(cursorLocation, "image", data.url);
            } else {
              alert('Upload Image fail');
            }
            resetUploader();
          })
          .catch(err => {
            alert('Upload Image Fail');
            console.log(err);
          });
    }
  },

  mounted() {
    extend('featuredImage', {
      ...mimes,
      message: () => 'Featured Image Type Wrong',
    });

    extend('nameRequired', {
      ...required,
      message: () => 'Name is required',
    });

    extend('salePriceAndOriginal', salePriceAndOriginalRuleObj);

    extend('originalPriceAndSale', originalPriceAndSaleRuleObj);

    this.loading = true;
    this.$store.dispatch('productCategory/getProductCategoryList')
        .finally(() => {
          this.loading = false;
        });
  }
}
</script>

<style scoped>

</style>