import {API_ROUTES} from "../utils/constants";
import api from "../utils/api";

export default {
    namespaced: true,

    state: {
        orderList: [],
        orderItem: null,
    },

    mutations: {
        SET_ORDER_LIST(state, orderList) {
            state.orderList = orderList;
        },

        SET_ORDER_ITEM(state, orderItem) {
            state.orderItem = orderItem;
        }
    },

    actions: {
        getOrderList({ commit }, isAdmin = false) {
            let apiUrl = isAdmin ? API_ROUTES.admin.orders : API_ROUTES.owner.orders.index;

            return api.get(apiUrl)
                .then(res => {
                    let { success, data } = res.data;

                    if(success) {
                        commit('SET_ORDER_LIST', data);
                    }

                    return res;
                });
        },

        changeStatus(context , { id, status, deliveryFee }) {
            return api.patch(API_ROUTES.owner.orders.changeStatus, {
                id,
                status,
                delivery_fee: deliveryFee
            })
        },

        findOrder({ commit }, uuid) {
            return api.get(`${API_ROUTES.owner.orders.index}/${uuid}`)
                .then(res => {
                    let { success, data } = res.data;

                    if(success) {
                        commit('SET_ORDER_ITEM', data);
                    }

                    return res;
                });
        },

        resetOrderItem({ commit }) {
            commit('SET_ORDER_ITEM', null);
        }
    }
}