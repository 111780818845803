// Containers
import ShopEdit from "../views/shops/ShopEdit";
import ProductCreate from "../views/products/ProductCreate";
import ProductEdit from "../views/products/ProductEdit";
import User from "../views/users/User";

const OrderList = () => import("@/views/orders/OrderList")

const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

// Product Categories
const ProductCategories = () => import('@/views/product_categories/Categories')
const ProductList = () => import('@/views/products/ProductList')

// Shop Categories
const ShopCategories = () => import('@/views/shop_categories/Categories')
const ShopList = () => import('@/views/shops/ShopList')
const ShopCreate = () => import('@/views/shops/ShopCreate')
const ShopOwners = () => import('@/views/shop_owners/ShopOwners')

export default [
    {
        path: '/',
        redirect: {name: 'Dashboard'},
        name: 'Home',
        component: TheContainer,
        meta: {auth: true},
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard
            },

            // Product Routes
            {
                path: 'products',
                redirect: '/products',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Products',
                        component: ProductList,
                    },
                    {
                        path: 'create',
                        name: 'ProductCreate',
                        component: ProductCreate,
                    },
                    {
                        path: 'edit/:id',
                        name: 'ProductEdit',
                        component: ProductEdit
                    },
                    {
                        path: 'categories',
                        name: 'ProductCategories',
                        component: ProductCategories
                    }
                ]
            },
            {
                path: 'shops',
                redirect: '/shops',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Shops',
                        component: ShopList,
                    },
                    {
                        path: 'create',
                        name: 'ShopCreate',
                        component: ShopCreate,
                    },
                    {
                        path: 'edit/:uuid',
                        name: 'ShopEdit',
                        component: ShopEdit
                    },
                    {
                        path: 'categories',
                        name: 'ShopCategories',
                        component: ShopCategories,
                    },
                    {
                        path: 'owners',
                        name: 'Owners',
                        component: ShopOwners,
                    },
                ]
            },
            {
                path: 'orders',
                redirect: '/orders',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Orders',
                        component: OrderList,
                    },
                ]
            },
            {
                path: 'me',
                component: User,
                name: 'Me'
            }
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '404',
                name: 'Page404',
                component: Page404
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500
            },
            // {
            //   path: 'login',
            //   name: 'Login',
            //   component: Login
            //   },
            // {
            //     path: 'register',
            //     name: 'Register',
            //     component: Register
            // }
        ]
    }
];
