import VueSelect from "vue-select";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import VueSweetAlert2 from 'vue-sweetalert2';
import VueMeta from "vue-meta";
import VueRx from 'vue-rx';
import VueJsClipper from 'vuejs-clipper'

const GlobalComponents = {
    install(Vue) {
        Vue.use(BootstrapVue);
        Vue.use(BootstrapVueIcons);
        Vue.use(VueSweetAlert2);
        Vue.use(VueMeta);
        Vue.use(VueRx);
        Vue.use(VueJsClipper);
        Vue.component('v-select', VueSelect);
    }
}

export default GlobalComponents;
