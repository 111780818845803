import {API_ROUTES, LOCAL_STORAGE_USER_KEY} from "../utils/constants";
import api from "../utils/api";

export default {
    namespaced: true,

    state: {
        user: null,
    },

    mutations: {
        SET_USER(state, user) {
            state.user = user;
        }
    },

    actions: {
        prefill() {
            return api.get(API_ROUTES.prefill);
        },

        /**
         * Do Login
         *
         * @param commit
         * @param data
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        doLogin({commit}, data) {
            return api.post(API_ROUTES.login, data)
                .then(res => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_USER', data);
                        localStorage.setItem(LOCAL_STORAGE_USER_KEY, data);
                    }

                    return res;
                });
        },

        /**
         * Check authenticated or not
         * @returns {Promise<AxiosResponse<any>>}
         */
        checkAuthenticated({commit}) {
            return api.get(API_ROUTES.checkAuthenticated)
                .then(res => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_USER', data);
                        localStorage.setItem(LOCAL_STORAGE_USER_KEY, data);
                    } else {
                        commit('SET_USER', null);
                        localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
                    }

                    return res;
                });
        },

        /**
         * Do Logout
         */
        doLogout({commit}) {
            return api.post(API_ROUTES.logout)
                .then((res) => {
                    commit('SET_USER', null);
                    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

                    return res;
                })
        },

        /**
         * Update User Info
         */
        updateInfo({commit}, {name, password, password_confirmation}) {
            return api.patch(API_ROUTES.updateMe, {
                name,
                password,
                password_confirmation
            })
                .then(res => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_USER', data);
                    }

                    return res;
                });
        }
    }
}
