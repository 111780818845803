import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./utils/router";
import CoreuiVue from "@coreui/vue";
import {iconsSet as icons} from "./assets/icons/icons.js";
import store from "./utils/store";
// Vue loader overlay
import "./utils/vue-loader-overlay";
import GlobalComponents from "./utils/globalComponents";

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

Vue.use(GlobalComponents);

new Vue({
    el: "#app",
    router,
    store,
    icons,
    template: "<App/>",
    components: {
        App,
    },
});
