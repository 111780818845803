<template>
<div>
  <div class="preview mb-2">
    <div class="media-wrapper-1x1">
      <img class="img-cover" :src="defaultPreviewSrc" alt="Shop Avatar" />
    </div>
  </div>
  <div class="input">
    <input type="file" @change="changeFile" accept="image/png,image/jpeg,image/svg+xml"/>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProductUploadFeatured',

  props: {
    value: File,
    defaultSrc: {
      type: String,
      required: false,
      default: '/no-image.jpg',
    }
  },

  computed: {
    defaultPreviewSrc() {
      if(this.imageSrc !== '') {
        return this.imageSrc;
      }

      return this.defaultSrc;
    }
  },

  data() {
    return {
      imageSrc: '',
    };
  },

  methods: {
    changeFile(evt) {
       this.imageSrc = URL.createObjectURL(evt.target.files[0]);
       this.$emit('input', evt.target.files[0]);
       this.$emit('change', evt.target.files[0]);
    }
  }
}
</script>
