export const LOCAL_STORAGE_USER_KEY = 'user';

// User Role
export const USER_ROLE_NORMAL = 0;
export const USER_ROLE_OWNER = 1;
export const USER_ROLE_ADMIN = 2;

// Location Type
export const LOCATION_TYPE_CITY = 3;
export const LOCATION_TYPE_DISTRICT = 2;
export const LOCATION_TYPE_WARD = 1;

// PRODUCT STATUS
export const PRODUCT_STATUS_OUT_OF_STOCK = 0;
export const PRODUCT_STATUS_IN_STOCK = 1;
export const PRODUCT_STATUS_STOP_BUSINESS = 2;

export const productStatusList = [
    {code: PRODUCT_STATUS_OUT_OF_STOCK, label: '売切'},
    {code: PRODUCT_STATUS_IN_STOCK, label: '在庫あり'},
    {code: PRODUCT_STATUS_STOP_BUSINESS, label: '取扱停止'},
];

// ORDER STATUS LIST
export const ORDER_STATUS_HOLD_ON = 1;
export const ORDER_STATUS_RECEIVED = 2;
export const ORDER_STATUS_PAYMENT = 3;
export const ORDER_STATUS_DELIVERING = 4;
export const ORDER_STATUS_DELIVERED = 5;
export const ORDER_STATUS_CANCELED = 6;

export const orderStatusList = [
    {code: ORDER_STATUS_HOLD_ON, label: 'Hold On'},
    {code: ORDER_STATUS_RECEIVED, label: 'Received'},
    {code: ORDER_STATUS_PAYMENT, label: 'Paid'},
    {code: ORDER_STATUS_DELIVERING, label: 'Delivering'},
    {code: ORDER_STATUS_DELIVERED, label: 'Delivered'},
    {code: ORDER_STATUS_CANCELED, label: 'Canceled'}
]

export const expectedDelieryTimeList = [
    { code: 'today', label: 'Today' },
    { code: 'tomorrow', label: 'Tomorrow' },
    { code: 'after-tomorrow', label: 'Day After Tomorrow' },
]

// Editor ToolBar
export const editorToolbar = [[{
    header: [false, 1, 2, 3, 4, 5, 6]
}], ["bold", "italic", "underline", "strike"], // toggled buttons
    [{
        align: ""
    }, {
        align: "center"
    }, {
        align: "right"
    }, {
        align: "justify"
    }],
    [
        "blockquote",
        // "code-block"
    ], [{
        list: "ordered"
    }, {
        list: "bullet"
    }, {
        list: "check"
    }], [{
        indent: "-1"
    }, {
        indent: "+1"
    }], // outdent/indent
    [{
        color: []
    }, {
        background: []
    }], // dropdown with defaults from theme
    [
        // "link",
        "image",
        // "video"
    ], ["clean"] // remove formatting button
];

// API Routes
export const API_ROUTES = {
    prefill: '/prefill',
    login: '/login',
    checkAuthenticated: '/is-authenticated',
    logout: '/logout',
    updateMe: '/me/update',

    admin: {
        // Product Category Routes
        productCategoriesTable: '/admin/product/categories-table',
        productCategories: '/admin/product/categories',

        // Shop Category Routes
        shopCategoriesTable: '/admin/shop/categories-table',
        shopCategories: '/admin/shop/categories',

        // Shop Owners Routes
        shopOwners: '/admin/shop/owners',

        // Shops Routes
        shops: '/admin/shops',

        // Product
        products: '/admin/products',

        // Orders
        orders: '/admin/orders',
    },

    owner: {
        products: '/owner/products',
        orders: {
            index: '/owner/orders',
            changeStatus: '/owner/orders/update-status',
        }
    },

    gallery: {
        upload: '/galleries/upload'
    },

    locations: '/locations',

    //Product Categories
    productCategories: '/product/categories',
    // Products
    products: '/products',
    // Order
    orders: {
        index: '/orders',
    },
};
