import api from "../utils/api";
import {API_ROUTES} from "../utils/constants";

export default {
    namespaced: true,

    state: {
        productCategoryTable: [],
        tableLoading: false,
        productCategoryList: [],
        productCateItem: null,
    },

    mutations: {
        SET_TABLE_LOADING(state, value) {
            state.tableLoading = value;
        },

        SET_PRODUCT_CATEGORY_TABLE(state, categoryList) {
            state.productCategoryTable = categoryList;
        },

        SET_PRODUCT_CATEGORY_LIST(state, categoryList) {
            state.productCategoryList = categoryList;
        },

        SET_PRODUCT_CATE_ITEM(state, cateItem) {
            state.productCateItem = cateItem;
        }
    },

    actions: {
        /**
         * Get Product Category List For Table
         * @param commit
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getProductCategoryTable({ commit }) {
            commit('SET_TABLE_LOADING', true);
            return api.get(API_ROUTES.admin.productCategoriesTable).then((res) => {
                let { success, data } = res.data;

                if (success) {
                    let productCategoryList = data.map((cateItem) => {
                        return {
                            ...cateItem,
                            parent_name: cateItem.parent_name ?? "",
                        };
                    });
                    commit("SET_PRODUCT_CATEGORY_TABLE", productCategoryList);
                }

                return res;
            })
                .finally(() => {
                    commit('SET_TABLE_LOADING', false);
                });
        },

        /**
         * Get Product Category List
         *
         * @param commit
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getProductCategoryList({commit}, isAdmin = false) {
            let apiRoute = isAdmin ? API_ROUTES.admin.productCategories : API_ROUTES.productCategories;
            return api.get(apiRoute).then((res) => {
                let {success, data} = res.data;

                if (success) {
                    commit("SET_PRODUCT_CATEGORY_LIST", data);
                }

                return res;
            });
        },

        createProductCategory(context, data) {
            return api.post(API_ROUTES.admin.productCategories, data);
        },

        updateProductCategory(context, {id, data}) {
            return api.patch(`${API_ROUTES.admin.productCategories}/${id}`, data);
        },

        deleteProductCategory(context, id) {
            return api.delete(`${API_ROUTES.admin.productCategories}/${id}`);
        },

        findProductCategory({ commit }, cateId) {
            return api.get(`${API_ROUTES.admin.productCategories}/${cateId}`)
                .then(res => {
                    let { success, data } = res.data;

                    if (success) {
                        commit('SET_PRODUCT_CATE_ITEM', data);
                    }

                    return res;
                });
        }
    },
};
