import api from "../utils/api";
import {API_ROUTES, LOCATION_TYPE_CITY, LOCATION_TYPE_DISTRICT, LOCATION_TYPE_WARD} from "../utils/constants";

export default {
    namespaced: true,

    state: {
        cityList: [],
        districtList: [],
        wardList: [],

        addressItem: null,
    },

    mutations: {
        SET_CITY_LIST(state, cityList) {
            state.cityList = cityList;
        },

        SET_DISTRICT_LIST(state, districtList) {
            state.districtList = districtList;
        },

        SET_WARD_LIST(state, wardList) {
            state.wardList = wardList
        },

        SET_ADDRESS_ITEM(state, addressItem) {
            state.addressItem  = addressItem;
        }
    },

    actions: {
        getLocationList({commit}, {type, cityId = null, districtId = null}) {
            return api.get(API_ROUTES.locations, {
                params: {
                    type,
                    city_id: cityId,
                    district_id: districtId,
                }
            })
                .then(res => {
                    let {data} = res.data;
                    if (type === LOCATION_TYPE_CITY) {
                        commit('SET_CITY_LIST', data);
                    } else if (type === LOCATION_TYPE_DISTRICT) {
                        commit('SET_DISTRICT_LIST', data);
                    } else if (type === LOCATION_TYPE_WARD) {
                        commit('SET_WARD_LIST', data);
                    }
                });
        },

        resetLocationList({commit}, type) {
            if (type === LOCATION_TYPE_CITY) {
                commit('SET_CITY_LIST', []);
            } else if (type === LOCATION_TYPE_DISTRICT) {
                commit('SET_DISTRICT_LIST', []);
            } else if (type === LOCATION_TYPE_WARD) {
                commit('SET_WARD_LIST', []);
            }
        },

        getAddressInfo({ commit }, addressId) {
            return api.get(`${API_ROUTES.locations}/address/${addressId}`)
                .then(res => {
                    let { success, data } = res.data;

                    if(success) {
                        commit('SET_ADDRESS_ITEM', data);
                    }

                    return res;
                })
        }
    }
}
