import {API_ROUTES, PRODUCT_STATUS_IN_STOCK} from "../utils/constants";
import api from "../utils/api";

export default {
    namespaced: true,

    state: {
        productList: [],
        productItem: null,
    },

    mutations: {
        SET_PRODUCT_LIST(state, productList) {
            state.productList = productList;
        },

        SET_PRODUCT_ITEM(state, productItem) {
            state.productItem = productItem;
        }
    },

    actions: {
        getProductList({ commit }, isAdmin = false) {
            let apiRoute = isAdmin ?  API_ROUTES.admin.products : API_ROUTES.owner.products;

            return api.get(apiRoute)
                .then(res => {
                    let { success, data} = res.data;

                    if(success) {
                        commit('SET_PRODUCT_LIST', data);
                    }

                    return res;
                });
        },

        findProduct({ commit }, {id, isAdmin = false}) {
            let apiRoute = `${isAdmin ? API_ROUTES.admin.products : API_ROUTES.owner.products}/${id}`;

            return api.get(apiRoute)
                .then(res => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_PRODUCT_ITEM', data);
                    }
                    return res;
                });
        },

        createProduct(context, {
            name,
            featured_image,
            price,
            original_price = null,
            category_ids = [],
            status = PRODUCT_STATUS_IN_STOCK,
            unit = null,
            excerpt = null,
            description = null
        }) {
            return api.post(API_ROUTES.owner.products, {
                name,
                featured_image,
                price,
                original_price,
                category_ids,
                status,
                unit,
                excerpt,
                description
            });
        },

        updateProduct(context, {
            id, data: {
                name,
                featured_image,
                price,
                original_price = null,
                category_ids = [],
                status = PRODUCT_STATUS_IN_STOCK,
                unit = null,
                excerpt = null,
                description = null
            }
        }) {
            return api.patch(`${API_ROUTES.owner.products}/${id}`, {
                name,
                featured_image,
                price,
                original_price,
                category_ids,
                status,
                unit,
                excerpt,
                description
            });
        },

        deleteProduct(context, id) {
            return api.delete(`${API_ROUTES.owner.products}/${id}`);
        }
    }
}