import api from "../utils/api";
import {API_ROUTES} from "../utils/constants";

export default {
    namespaced: true,

    actions: {
        uploadImage(context, {file, type, responseType = ''}) {

            let formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);
            formData.append('return_type', responseType);

            return api.post(API_ROUTES.gallery.upload, formData, {
                headers: {
                    'Content-Type': 'multipart'
                }
            });
        }
    }
}