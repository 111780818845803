<template>
  <div>
    <CRow ref="shopCategorySection">
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="d-flex align-items-center">
                <CIcon name="cil-house" class="mr-2" />
                Edit Shop
              </div>
            </slot>
          </CCardHeader>

          <CCardBody>
            <ValidationObserver v-slot="{ handleSubmit }">
              <CForm role="form" @submit.prevent="handleSubmit(updateShop)">
                <CRow>
                  <CCol col="12">
                    <h5 class="font-weight-bold">Base Information</h5>
                    <hr>
                  </CCol>
                  <CCol col="12" md="4" xl="2">
                    <ValidationProvider
                        :rules="{ avatarImage: ['image/png','image/svg+xml','image/jpeg'] }"
                        v-slot="{ errors, invalid, validated }"
                    >
                      <UploadAvatar v-model="shop.avatar" :default-src="shop.avatar_url" @change="changeAvatar"/>
                      <div class="text-danger mt-2" v-if="validated && invalid && errors[0]">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol col="12" md="8" xl="10">
                    <CRow>
                      <CCol col="12" md="6">
                        <ValidationProvider
                            :rules="{ nameRequired: true }"
                            v-slot="{ errors, invalid, validated }"
                        >
                          <label>Name <span class="text-danger">*</span> </label>
                          <CInput
                              placeholder="Enter Shop Name"
                              v-model="shop.name"
                              :isValid="!validated ? null : !invalid"
                          >
                            <template #invalid-feedback v-if="errors[0]">
                              <div class="invalid-feedback">{{ errors[0] }}</div>
                            </template>
                          </CInput>
                        </ValidationProvider>
                      </CCol>
                      <CCol col="12" md="6">
                        <label>Owner</label>
                        <v-select :options="ownerOptionsList" :reduce="ownerItem => ownerItem.code"
                                  v-model="shop.ownerId"></v-select>
                      </CCol>
                      <CCol col="12" class="mb-3">
                        <label>Category</label>
                        <v-select :multiple="true" :options="categoryListOptions"
                                  :reduce="categoryItem => categoryItem.code" v-model="shop.categoryIds"></v-select>
                      </CCol>
                      <CCol col="12">
                        <CTextarea
                            label="Description"
                            v-model="shop.description"
                            rows="3"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol col="12" class="mt-4">
                    <h5 class="font-weight-bold">Address</h5>
                    <hr>
                  </CCol>
                  <CCol col="12" md="4" class="mb-2">
                    <label>City / Province</label>
                    <v-select :options="cityOptions" v-model="shop.cityId" :reduce="cityItem => cityItem.code"
                              @input="changeCity"></v-select>
                  </CCol>
                  <CCol col="12" md="4" class="mb-2">
                    <label>District / City</label>
                    <v-select :options="districtOptions" v-model="shop.districtId"
                              :reduce="districtItem => districtItem.code" @input="changeDistrict"></v-select>
                  </CCol>
                  <CCol col="12" md="4" class="mb-2">
                    <label>Ward</label>
                    <v-select :options="wardOptions" v-model="shop.wardId"
                              :reduce="wardItem => wardItem.code"></v-select>
                  </CCol>
                  <CCol col="12">
                    <CInput label="Address" v-model="shop.address"/>
                  </CCol>
                  <CCol class="text-center text-danger" col="12" v-if="errorMessage !== ''">
                    {{ errorMessage }}
                  </CCol>

                  <CCol col="12" class="mt-3">
                    <hr>
                  </CCol>
                  <CCol col="12" class="text-center">
                    <CButton color="primary" type="submit">Save</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>

      <CElementCover :opacity="0.8" v-if="loading" />
    </CRow>
  </div>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {mimes, required} from "vee-validate/dist/rules";
import {mapState} from "vuex";
import UploadAvatar from "../../components/shop/UploadAvatar";
import {LOCATION_TYPE_CITY, LOCATION_TYPE_DISTRICT, LOCATION_TYPE_WARD} from "../../utils/constants";

export default {
  name: 'ShopEdit',

  components: {
    UploadAvatar,
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState({
      shopItem: state => state.shop.shopItem ?? {},
      ownerList: state => state.shopOwner.ownerList,
      categoryList: state => state.shopCategory.shopCategoryList,
      cityList: state => state.location.cityList,
      districtList: state => state.location.districtList,
      wardList: state => state.location.wardList
    }),

    shop() {
      return {
        name: this.shopItem.name,
        ownerId: this.shopItem.owner ? this.shopItem.owner.uuid : null,
        avatar: this.shopItem.avatar ?? null,
        avatar_url: this.shopItem.avatar_url,
        description: this.shopItem.description,
        cityId: this.shopItem.city_id,
        districtId: this.shopItem.district_id,
        wardId: this.shopItem.ward_id,
        address: this.shopItem.address,
        categoryIds: this.shopItem.category_ids,
      }
    },

    ownerOptionsList() {
      return this.ownerList.map(ownerItem => {
        return {
          code: ownerItem.uuid,
          label: ownerItem.name
        }
      });
    },

    categoryListOptions() {
      return this.categoryList.map(categoryItem => {
        return {
          code: categoryItem.id,
          label: categoryItem.name,
        }
      });
    },

    cityOptions() {
      return this.cityList.map(cityItem => {
        return {
          code: cityItem.id,
          label: cityItem.name
        }
      });
    },

    districtOptions() {
      return this.districtList.map(districtItem => {
        return {
          code: districtItem.id,
          label: districtItem.name
        }
      });
    },


    wardOptions() {
      return this.wardList.map(wardItem => {
        return {
          code: wardItem.id,
          label: wardItem.name
        }
      });
    }
  },

  data() {
    return {
      loading: false,
      shopUuid: '',
      errorMessage: '',
    };
  },

  methods: {
    updateShop() {
      this.loading = true;
      this.errorMessage = '';
      console.log(this.shop);

      this.$store.dispatch('shop/updateShop', {id: this.shopUuid, data: this.shop})
          .then((res) => {
            let {success, message} = res.data;
            if (success) {
              this.$router.push({name: 'Shops'});
            } else {
              this.errorMessage = message
            }
          })
          .catch(err => {
            let response = err.response;

            if (response.status !== 422) {
              this.errorMessage = "Edit Shop Failed";
            } else {
              let errors = response.data.errors;
              let errorKey = Object.keys(errors)[0];

              this.errorMessage = errors[errorKey][0];
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },

    changeAvatar() {
      console.log('Change Avatar');
    },

    changeCity(newCityId) {
      this.shop.cityId = newCityId;
      this.shop.districtId = null;

      if (this.shop.cityId) {
        this.$store.dispatch('location/getLocationList', {
          type: LOCATION_TYPE_DISTRICT,
          cityId: this.shop.cityId,
        });
      } else {
        this.$store.dispatch('location/resetLocationList', LOCATION_TYPE_DISTRICT);
      }

      this.changeDistrict(this.shop.districtId);
    },

    changeDistrict(newDistrictId) {
      this.shop.districtId = newDistrictId;
      this.shop.wardId = null;

      if (this.shop.districtId) {
        this.$store.dispatch('location/getLocationList', {
          type: LOCATION_TYPE_WARD,
          districtId: this.shop.districtId
        });
      } else {
        this.$store.dispatch('location/resetLocationList', LOCATION_TYPE_WARD);
      }
    }
  },

  mounted() {
    this.loading = true;

    this.shopUuid = this.$route.params.uuid;

    extend("nameRequired", {
      ...required,
      message: () => "Shop name is required",
    });
    extend('avatarImage', {
      ...mimes,
      message: () => "Shop avatar has wrong format",
    });

    this.$store.dispatch('shop/findShop', this.shopUuid)
        .then(res => {
          let {success, data, message} = res.data;

          if (success) {
            let apiFetchs = [
              this.$store.dispatch('shopOwner/getOwnerList'),
              this.$store.dispatch('shopCategory/getShopCategoryList'),
              this.$store.dispatch('location/getLocationList', {type: LOCATION_TYPE_CITY}),
            ];

            // If have city_id, load the district list
            if (data.city_id) {
              apiFetchs.push(this.$store.dispatch('location/getLocationList', {
                type: LOCATION_TYPE_DISTRICT,
                cityId: data.city_id
              }));
            }


            // If have district_id, load ward list
            if (data.district_id) {
              apiFetchs.push(this.$store.dispatch('location/getLocationList', {
                type: LOCATION_TYPE_WARD,
                districtId: data.district_id
              }));
            }

            return Promise.all(apiFetchs);
          } else {
            this.errorMessage = message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
  },
  metaInfo: {
    title: "Edit Shop",
  },
};
</script>
