var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateUser)}}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Account Information ")]),_c('CCardBody',[_c('ValidationProvider',{attrs:{"rules":{ nameRequired: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('label',[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('CInput',{attrs:{"placeholder":"Enter Shop Name","isValid":!validated ? null : !invalid},scopedSlots:_vm._u([(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('CInput',{attrs:{"disabled":true,"label":"Email","value":_vm.user.email}}),(_vm.user.role_id === _vm.USER_ROLE_OWNER)?_c('CInput',{attrs:{"disabled":true,"label":"Shop","value":_vm.user.shop_name}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"password"}},[_c('CInput',{attrs:{"type":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('ValidationProvider',{attrs:{"rules":{ passwordConfirmRequired: ['password'], passwordConfirm: ['password'] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"type":"password","label":"Password Confirmation","isValid":!validated ? null : !invalid},scopedSlots:_vm._u([(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})]}}],null,true)}),(_vm.result.message !== '')?_c('div',{class:['text-center', ("text-" + (_vm.result.className))],attrs:{"col":"12"}},[_vm._v(" "+_vm._s(_vm.result.message)+" ")]):_vm._e()],1),_c('CCardFooter',{staticClass:"text-center"},[_c('CButton',{attrs:{"type":"submit","color":"primary","size":"sm"}},[_vm._v("Save")])],1)],1)],1)]}}])}),(_vm.loading)?_c('CElementCover'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }