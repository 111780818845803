<template>
  <CRow>
    <CCol col="12">
      <ValidationObserver v-slot="{ handleSubmit }">
        <CForm role="form" @submit.prevent="handleSubmit(updateUser)">
          <CCard>
            <CCardHeader>
              Account Information
            </CCardHeader>
            <CCardBody>
              <ValidationProvider
                  :rules="{ nameRequired: true }"
                  v-slot="{ errors, invalid, validated }"
              >
                <label>Name <span class="text-danger">*</span> </label>
                <CInput
                    placeholder="Enter Shop Name"
                    v-model="user.name"
                    :isValid="!validated ? null : !invalid"
                >
                  <template #invalid-feedback v-if="errors[0]">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </template>
                </CInput>
              </ValidationProvider>

              <CInput :disabled="true" label="Email" :value="user.email"/>

              <CInput :disabled="true" label="Shop" :value="user.shop_name" v-if="user.role_id === USER_ROLE_OWNER"/>

              <ValidationProvider vid="password">
                <CInput
                    type="password"
                    label="Password"
                    v-model="password"
                >
                </CInput>
              </ValidationProvider>

              <ValidationProvider
                  :rules="{ passwordConfirmRequired: ['password'], passwordConfirm: ['password'] }"
                  v-slot="{ errors, invalid, validated }"
              >
                <CInput
                    type="password"
                    label="Password Confirmation"
                    v-model="password_confirmation"
                    :isValid="!validated ? null : !invalid"
                >
                  <template #invalid-feedback v-if="errors[0]">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </template>
                </CInput>
              </ValidationProvider>

              <div :class="['text-center', `text-${result.className}`]" col="12" v-if="result.message !== ''">
                {{ result.message }}
              </div>
            </CCardBody>
            <CCardFooter class="text-center">
              <CButton type="submit" color="primary" size="sm">Save</CButton>
            </CCardFooter>
          </CCard>
        </CForm>
      </ValidationObserver>

      <CElementCover v-if="loading"/>
    </CCol>
  </CRow>
</template>

<script>
import {mapState} from "vuex";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {confirmed, required, required_if} from "vee-validate/dist/rules";
import {USER_ROLE_OWNER} from "../../utils/constants";

export default {
  name: 'User',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.user ?? {},
    }),

    user() {
      return {
        ...this.userInfo,
      }
    }
  },

  data() {
    return {
      USER_ROLE_OWNER,

      password: '',
      password_confirmation: '',

      loading: false,

      result: {
        className: '',
        message: '',
      }
    };
  },

  methods: {
    updateUser() {
      this.loading = true;

      this.result = {className: '', message: ''};

      this.$store.dispatch('auth/updateInfo', {
        name: this.user.name,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
          .then(res => {
            let {success} = res.data;

            if (success) {
              this.result = {
                className: 'success',
                message: 'Update Info Success',
              }
            } else {
              this.result = {
                className: 'danger',
                message: 'Update Info Failed',
              }
            }
          })
          .catch(() => {
            this.result = {
              className: 'danger',
              message: 'Update Info Failed',
            }
          })
          .finally(() => {
            this.loading = false;
          })
    }
  },

  mounted() {
    extend('nameRequired', {
      ...required,
      message: () => "Name is required"
    });
    extend('passwordConfirmRequired', {
      ...required_if,
      message: () => 'Password Confirmation is required when password present',
    });
    extend('passwordConfirm', {
      ...confirmed,
      message: () => 'Password and Password Confirmation is not match'
    });
  }
}
</script>
