import auth from "./auth";
import productCategory from "./productCategory";
import shopCategory from './shopCategory';
import shopOwner from './shopOwner';
import shop from './shop';
import location from './location';
import product from './product';
import gallery from './gallery';
import order from './order';

export default {
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false
    },

    mutations: {
        toggleSidebarDesktop(state) {
            const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        toggleSidebarMobile(state) {
            const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarClosed ? true : 'responsive'
        },
        set(state, [variable, value]) {
            state[variable] = value
        }
    },

    modules: {
        auth,
        location,
        productCategory,
        shopCategory,
        shopOwner,
        shop,
        product,
        gallery,
        order,
    }
}
