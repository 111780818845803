import Vue from "vue";
import VueRouter from "vue-router";
import routes from '../routes';
import {LOCAL_STORAGE_USER_KEY} from "./constants";

Vue.use(VueRouter);

const router =  new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes
})

router.beforeEach((to, from, next) => {
    // Check authenticate
    if (to.matched.some(record => record.meta.auth)) {
        // redirect to login page if not logged in and trying to access a restricted page
        let user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);

        if (!user) {
            return next('/login');
        }
    }

    let authorize = null;
    to.matched.some(record => {
        if (record.meta.authorize) {
            authorize = record.meta.authorize;
            return true;
        }
    });

    if(authorize) {
        let user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);

        if(!user) {
            return next('/login');
        }

        let userJson = JSON.parse(user);

        if(userJson.role_id <= authorize) {
            return next('/forbidden');
        }
    }

    next();
})

export default router;
