import api from "../utils/api";
import {API_ROUTES} from "../utils/constants";

export default {
    namespaced: true,

    state: {
        ownerList: [],
        ownerItem: null,
        tableLoading: false,
    },

    mutations: {
        SET_LOADING(state, value) {
            state.tableLoading = value;
        },

        SET_OWNER_LIST(state, ownerList) {
            state.ownerList = ownerList;
        }
    },

    actions: {
        getOwnerList({ commit }) {
            commit('SET_LOADING', true);

            return api.get(API_ROUTES.admin.shopOwners)
                .then(res => {
                    let { success, data } = res.data;

                    if (success) {
                        commit('SET_OWNER_LIST', data);
                    }

                    return res;
                })
                .finally(() => {
                    commit('SET_LOADING', false);
                });
        },

        createOwner(context, data) {
            return api.post(API_ROUTES.admin.shopOwners, data);
        },

        findOwner(context, id) {
            return api.get(`${API_ROUTES.admin.shopOwners}/${id}`);
        },

        updateOwner(context, {id, data}) {
            return api.patch(`${API_ROUTES.admin.shopOwners}/${id}`, data);
        },

        deleteShowOwner(context, id) {
            return api.delete(`${API_ROUTES.admin.shopOwners}/${id}`);
        }
    },
}