/**
 * Format Vietname Currency
 * @param price
 * @returns {string}
 */
export const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
    }).format(price);
}

/** Vee Validation Custom Rules **/
export const salePriceAndOriginalRuleObj = {
    validate: (value, _a) => {
        let target = _a.target;
        return target ? Number(value) < Number(target) : true;
    },
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ],
    message: () => 'The selling price must be less than the original price'
}

export const originalPriceAndSaleRuleObj = {
    validate: (value, _a) => {
        let target = _a.target;
        return value && target ? Number(value) > Number(target) : true;
    },
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ],
    message: () => 'The selling price must be less than the original price'
}
/** End Vee Validation Custom Rules **/