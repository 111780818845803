import api from "../utils/api";
import {API_ROUTES} from "../utils/constants";

export default {
    namespaced: true,

    state: {
        shopCategoryTable: [],
        tableLoading: false,
        shopCategoryList: [],
        shopCateItem: null,
    },

    mutations: {
        SET_TABLE_LOADING(state, value) {
            state.tableLoading = value;
        },

        SET_SHOP_CATEGORY_TABLE(state, categoryList) {
            state.shopCategoryTable = categoryList;
        },

        SET_SHOP_CATEGORY_LIST(state, categoryList) {
            state.shopCategoryList = categoryList;
        },

        SET_SHOP_CATE_ITEM(state, cateItem) {
            state.shopCateItem = cateItem;
        }
    },

    actions: {
        /**
         * Get Shop Category List For Table
         * @param commit
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getShopCategoryTable({ commit }) {
            commit('SET_TABLE_LOADING', true);
            return api.get(API_ROUTES.admin.shopCategoriesTable).then((res) => {
                let { success, data } = res.data;

                if (success) {
                    let shopCategoryList = data.map((cateItem) => {
                        return {
                            ...cateItem,
                            parent_name: cateItem.parent_name ?? "",
                        };
                    });
                    commit("SET_SHOP_CATEGORY_TABLE", shopCategoryList);
                }

                return res;
            })
                .finally(() => {
                    commit('SET_TABLE_LOADING', false);
                });
        },

        /**
         * Get Shop Category List
         *
         * @param commit
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getShopCategoryList({ commit }) {
            return api.get(API_ROUTES.admin.shopCategories).then((res) => {
                let { success, data } = res.data;

                if (success) {
                    commit("SET_SHOP_CATEGORY_LIST", data);
                }

                return res;
            });
        },

        createShopCategory(context, data) {
            return api.post(API_ROUTES.admin.shopCategories, data);
        },

        updateShopCategory(context, {id, data}) {
            return api.patch(`${API_ROUTES.admin.shopCategories}/${id}`, data);
        },

        deleteShopCategory(context, id) {
            return api.delete(`${API_ROUTES.admin.shopCategories}/${id}`);
        },

        findShopCategory({ commit }, cateId) {
            return api.get(`${API_ROUTES.admin.shopCategories}/${cateId}`)
                .then(res => {
                    let { success, data } = res.data;

                    if (success) {
                        commit('SET_SHOP_CATE_ITEM', data);
                    }

                    return res;
                });
        }
    },
};
