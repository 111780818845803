import api from "../utils/api";
import {API_ROUTES} from "../utils/constants";

export default {
    namespaced: true,

    state: {
        shopList: [],
        shopItem: null,
        tableLoading: false,
    },

    mutations: {
        SET_LOADING(state, value) {
            state.tableLoading = value;
        },
        SET_SHOP_LIST(state, shopList) {
            state.shopList = shopList;
        },

        SET_SHOP_ITEM(state, shopItem) {
            state.shopItem = shopItem;
        },
    },

    actions: {
        getShopList({ commit }) {
            commit('SET_LOADING', true);

            return api.get(API_ROUTES.admin.shops)
                .then((res) => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_SHOP_LIST', data);
                    }
                })
                .finally(() => {
                    commit('SET_LOADING', false);
                });
        },

        findShop({commit}, id) {
            return api.get(`${API_ROUTES.admin.shops}/${id}`)
                .then((res) => {
                    let {success, data} = res.data;

                    if (success) {
                        commit('SET_SHOP_ITEM', data);
                    }

                    return res;
                });
        },

        createShop(context, {
            avatar,
            name,
            description = null,
            cityId = null,
            districtId = null,
            wardId = null,
            address = null,
            categoryIds = [],
            ownerId = null,
        }) {
            let formData = new FormData();

            formData.append('avatar', avatar);
            formData.append('name', name);
            if (description) {
                formData.append('description', description);
            }
            if (cityId) {
                formData.append('city_id', cityId);
            }
            if (districtId) {
                formData.append('district_id', districtId);
            }
            if (wardId) {
                formData.append('ward_id', wardId);
            }
            if (address) {
                formData.append('address', address);
            }
            if (categoryIds.length) {
                formData.append('category_ids[]', categoryIds);
            }

            if (ownerId) {
                formData.append('owner_id', ownerId);
            }

            return api.post(API_ROUTES.admin.shops, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        updateShop(context, {
            id, data: {
                avatar,
                name,
                description = null,
                cityId = null,
                districtId = null,
                wardId = null,
                address = null,
                categoryIds = [],
                ownerId = null,
            }
        }) {
            let formData = new FormData();

            if (avatar) {
                formData.append('avatar', avatar);
            }
            if (name) {
                formData.append('name', name);
            }
            if (description) {
                formData.append('description', description);
            }
            if (cityId) {
                formData.append('city_id', cityId);
            }
            if (districtId) {
                formData.append('district_id', districtId);
            }
            if (wardId) {
                formData.append('ward_id', wardId);
            }
            if (address) {
                formData.append('address', address);
            }
            if (categoryIds.length) {
                formData.append('category_ids[]', categoryIds);
            }

            if (ownerId) {
                formData.append('owner_id', ownerId);
            }

            formData.append('_method', 'PATCH');

            return api.post(`${API_ROUTES.admin.shops}/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        deleteShop(context, id) {
            return api.delete(`${API_ROUTES.admin.shops}/${id}`);
        }
    }
}
