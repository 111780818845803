import axios from "axios";
import router from "./router";
import {LOCAL_STORAGE_USER_KEY} from "./constants";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});

api.defaults.headers.common['Accept'] = 'application/json';
api.defaults.headers.common['Content-Type'] = 'application/json';


api.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
        router.push({name: 'login'});
    }

    return Promise.reject(error);
})

export default api;
